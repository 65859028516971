@import "./custom-bootstrap-variables.scss";
@import "~bootstrap/scss/bootstrap.scss";

.btn {
  &:focus {
    box-shadow: unset;
  }
}

.btn-primary {
  color: white;
  background-color: var(--primary);
  &:focus {
    color: white;
    background-color: var(--primary);
  }
  &:hover,
  &.hover {
    color: var(--primary) !important;
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
  }
  &:active {
    color: var(--primary);
    background-color: var(--secondary) !important;
    border-color: var(--secondary) !important;
    box-shadow: unset !important;
  }
}
