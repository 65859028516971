body {
  font-weight: 400;
  background-color: #16151a !important;
  -webkit-font-smoothing: antialiased;
  font-family: "Inter", sans-serif !important;
  overflow-x: hidden;
}

.logo {
  height: 50px;
  width: auto;
}
.sign__input {
  background-color: #222227;
  border: 1px solid transparent;
  border-radius: 12px;
  height: 46px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 0 20px;
  font-family: "Inter", sans-serif;
}
.sign__input:focus {
  border-color: #6164ff;
}
.sign__btn {
  width: 100%;
  height: 50px;
  border-radius: 12px;
  background-color: #6164ff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 14px;
  position: relative;
  z-index: 2;
  text-transform: uppercase;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  margin: 15px auto;
}
.sign__btn:hover {
  color: #6164ff;
  background-color: #222227;
}
.imageContainer {
  position: relative;
  height: 100%;
  width: 100%;
}
.image__ {
  position: absolute;
  margin: auto;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.sign__form {
  background-color: #16151a;
  border-radius: 16px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  max-width: 600px;
  border: 1px solid #222227;
}
.centered {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.header {
  background: #222227;
  height: auto;
}

.footer__lang {
  position: relative;
}
.footer__lang-btn {
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 16px 0 8px;
  height: 36px;
  border-radius: 18px;
  border: none;
}
.footer__lang-btn img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
}
.footer__lang-btn span {
  color: #bdbdbd;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  transition: color 0.5s ease;
}
.footer__lang-btn:hover span {
  color: #fff;
}
.footer__lang-dropdown {
  display: block;
  position: absolute !important;
  z-index: -1;
  pointer-events: none;
  opacity: 0;
  top: 0;
  background-color: #16151a !important;
  border-radius: 16px;
  padding: 16px;
  min-width: 100px;
  transition: opacity 0.5s ease;
  height: auto;
  border: 1px solid #222227;
}
.footer__lang-dropdown li {
  width: 100%;
  margin-bottom: 15px;
}
.footer__lang-dropdown li:last-child {
  margin-bottom: 0;
}
.footer__lang-dropdown a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
}
.footer__lang-dropdown a img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
}
.footer__lang-dropdown a span {
  color: #bdbdbd;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  transition: color 0.5s ease;
}
.footer__lang-dropdown a:hover span {
  color: #fff;
}
.footer__lang-dropdown.show {
  z-index: 1000;
  pointer-events: auto;
  opacity: 1;
}
.card__wrapper {
  background-color: #16151a;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 16px;
  padding: 30px 20px;
  position: relative;
  width: 100%;
  border: 1px solid #222227;
}
.certifiedCard {
  position: absolute;
  bottom: -10px;
  right: -10px;
  border-radius: 50%;
  background: url("../assets/verified.svg") no-repeat center #2f80ed;
  content: "";
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  z-index: 1;
}
.author__code {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  height: 32px;
  background-color: #222227;
  border-radius: 10px;
  position: relative;
}
.author__code input {
  width: 100%;
  padding: 0 40px 0 15px;
  height: 30px;
  border: none;
  background-color: transparent;
  color: #bdbdbd;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  font-weight: 400;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-wrap: break-word;
  cursor: default;
  position: relative;
  z-index: 1;
  transition: 0s;
}
.author__code ::-moz-selection {
  background: transparent;
  color: #bdbdbd;
}
.author__code ::selection {
  background: transparent;
  color: #bdbdbd;
}
.author__code button {
  position: absolute;
  z-index: 2;
  cursor: pointer;
  width: 40px;
  top: 0;
  right: 0;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  transition: 0s;
}
.author__code button span {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: auto;
  background-color: #222227;
  border-radius: 8px;
  font-size: 12px;
  line-height: 22px;
  padding: 0 8px;
  color: #bdbdbd;
  bottom: 100%;
  margin-bottom: 5px;
  transform: scale(0);
  pointer-events: none;
  opacity: 0;
  transition: 0.4s ease;
  transition-property: opacity, transform;
}
.author__code button svg {
  fill: #6164ff;
  width: 18px;
  height: auto;
  opacity: 0.75;
  transition: opacity 0.5s ease;
}
.author__code button.active span {
  transform: scale(1);
  opacity: 1;
}
.author__code button.active svg {
  opacity: 1;
}
.author__code button:hover svg {
  opacity: 1;
}

.author__role__avatar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
}
.author__role__avatar--verified:after {
  position: absolute;
  display: block;
  bottom: 2px;
  left: 40px;
  border-radius: 50%;
  background: url("../assets/verified.svg") no-repeat center #2f80ed;
  content: "";
  width: 25px;
  height: 25px;
  border: 2px solid #fff;
  z-index: 1;
}
.author__role__avatar img {
  border: 5px solid #ffffff;
  display: block;
  overflow: hidden;
  width: 62px !important;
  height: 62px !important;
  border-radius: 10px;
  margin-right: 10px;
}
.asset__action {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  position: relative;
  margin-top: 20px;
}
.asset__action--verified:after {
  content: "";
  position: absolute;
  display: block;
  width: 18px;
  height: 18px;
  border: 2px solid #fff;
  bottom: -2px;
  left: 28px;
  border-radius: 50%;
  background: url("../assets/verified.svg") no-repeat center #2f80ed;
  background-size: 14px auto;
  z-index: 1;
  pointer-events: none;
}
.asset__action img {
  display: block;
  overflow: hidden;
  width: 44px !important;
  height: 44px !important;
  border-radius: 10px;
  margin-right: 10px;
}
.asset__actions--scroll {
  height: 400px;
  overflow: unset;
  overflow-y: scroll;
  margin-top: 20px;
}
.asset__actions--scroll .asset__action:first-child {
  margin-top: 0;
}
body::-webkit-scrollbar {
  width: 16px;
}
body::-webkit-scrollbar-track {
  background: #222227;
}
body::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  outline: 1px solid #222227;
}

div::-webkit-scrollbar-track {
  background-color: transparent;
}

div::-webkit-scrollbar {
  width: 5px;
  background-color: transparent;
}

div::-webkit-scrollbar-thumb {
  background-color: #6164ff;
  border-radius: 10px;
}
div.noScrollThumb::-webkit-scrollbar-track {
  display: none;
}
div.noScrollThumb::-webkit-scrollbar {
  display: none;
}
.noScrollThumb::-webkit-scrollbar-thumb {
  background-color: transparent;
}
.noScrollThumb {
  scroll-behavior: smooth;
}

@media (min-width: 576px) {
  .card__wrapper {
    padding: 40px;
  }
}
