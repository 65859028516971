$primary: #6164ff !default;
$secondary: #222227 !default;

$green: #61e295 !default;
$gray-medium: #bdbdbd !default;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "green": $green,
  "gray-medium": $gray-medium,
) !default;
