.cursor__pointer {
  cursor: pointer;
}

.certification__header_logo {
  width: 165px;
  height: 45px;
}

.certification__header_title {
  font-size: 35px;
}

.certification__header_description {
  color: var(--gray-medium);
  width: 45%;
}

.certification__title {
  font-size: 75px;
}

.certification__section__title {
  font-size: 45px;
}

.certification__share__qr {
  width: 100% !important;
  height: 100% !important;
  max-width: 340px;
  max-height: 340px;
}

.certification_author__code {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-top: 26px;
  height: 32px;
  border-radius: 10px;
  position: relative;
  input {
    width: 100%;
    border-radius: 16px;
    border: none;
    background: #16151a;
    mix-blend-mode: screen;
    color: #bdbdbd;
    font-size: 19px;
    padding-right: 60px !important;
    text-overflow: ellipsis;
    word-wrap: break-word;
  }
  button {
    position: absolute;
    z-index: 2;
    cursor: pointer;
    width: 40px;
    top: 3px;
    right: 10px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    transition: 0s;
    svg {
      fill: #4BA4E3;
      width: 25px;
      height: auto;
      opacity: 0.75;
      transition: opacity 0.5s ease;
    }
  }
}

@media screen and (max-width: 991px) {
  .certification__header_logo {
    width: 30%;
    min-width: 130px;
    height: "auto";
  }

  .certification__header_title {
    font-size: 22px;
  }

  .certification__header_description {
    width: 80%;
  }

  .certification__title {
    font-size: 31px;
  }

  .certification__section__title {
    font-size: 31px;
  }
}

@media screen and (max-width: 400px) {
  .certification__section__title {
    font-size: 24px;
  }
}
